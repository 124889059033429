<script setup>
import { ref, defineAsyncComponent } from 'vue'
// import { useAuthStore } from '@/store/auth'

const daftarpembelian = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/DaftarPembelian.vue')
)

const detailpembelian = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/DetailPembelian.vue')
)

const penerimaanharian = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/PenerimaanHarian.vue')
)

const historypembelian = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/HistoryPembelian.vue')
)

// const auth = useAuthStore()

let laporan = ref('daftarpembelian')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Daftar Pembelian',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'daftarpembelian'
    },
    visible: true, //auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Detail Pembelian',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'detailpembelian'
    },
    visible: true, //auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Penerimaan Harian',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'penerimaanharian'
    },
    visible: true, //auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan History Pembelian',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'historypembelian'
    },
    visible: true, //auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Laporan Pembelian</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Laporan / Pembelian</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <daftarpembelian
            v-if="laporan === 'daftarpembelian'"
            @toggle="onToggle"
          />
          <detailpembelian
            v-if="laporan === 'detailpembelian'"
            @toggle="onToggle"
          />
          <historypembelian
            v-if="laporan === 'historypembelian'"
            @toggle="onToggle"
          />
          <penerimaanharian
            v-if="laporan === 'penerimaanharian'"
            @toggle="onToggle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
